.contact__options {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.5rem 2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  width: 180px; 
  height: 120px;
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  transform: translateY(-5px);
}

.contact__option-icon {
  font-size: 2rem;
  margin-bottom: 0.7rem;
  transition: var(--transition);
}

.contact__option:hover .contact__option-icon {
  transform: scale(1.1);
}

.contact__content {
  text-align: center;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}

.contact__content p {
  margin: 1rem 0 1rem;
  color: var(--color-light);
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media (max-width: 768px) {
  .contact__options {
    flex-direction: column;
  }

  .contact__content p {
    margin: 1.5rem 0;
  }
}