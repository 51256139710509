.container.portfolio__swiper {
  /* width: 60%; */
  padding-bottom: 4rem;
}


.portfolio__slide {
  background-color: #fff;
  border-radius: 10%;
  overflow: hidden;
}

.portfolio__slide img {
  aspect-ratio: 1/1;
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding: 1px;
}