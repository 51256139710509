header {
  padding-top: 5rem;
  padding-bottom: 5rem;
  overflow: hidden;
  background-color: var(--color-primary);
}

.header__container {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 5%;
  place-items: center;
}

.header__content h1,
.header__content h2,
.header__content h3 {
  text-align: center;
}

.header__content h3 {
  color: var(--color-bg);
}

.image__container {
  width: 100%;
  /* aspect-ratio: 1/1; */
  border-radius: 2rem;
  /* background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  ); */
  display: grid;
  place-items: center;
}

.image__container-image {
  width: 100%;
  height: auto;
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(-10deg);
  transition: var(--transition);
}

.image__container-image:hover {
  transform: rotate(0);
}

/* =============== CTA ============== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .header__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .image__container {
    width: 70%;
    margin: 2rem auto 4rem;
  }

  .header__content p {
    margin: 1rem 0 1.5rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .image__container {
    width: 85%;
    margin: 0 auto 3rem;
  }

  .image__container-image {
    margin-top: 40px;
  }

  .header__content {
    text-align: center;
  }

  .header__content p {
    margin: 1.5rem 0;
  }
}
