.text-animate {
  /* display: inline-block; */
  position: relative;
  bottom: -1em;
  opacity: 0;
  animation: move-text 0.75s forwards;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

.text-animate:hover {
  color: var(--color-yellow);
  animation: move-text 1s infinite;
  animation-fill-mode: both;
}

span {
  position: relative;
  animation: move-text 0.75s forwards;
  bottom: -1em;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  /* .text-animate-hover:hover {
    color: #ffd700;
    animation: none;
  } */
}

@keyframes move-text {
  0% {
    bottom: -0.2em;
    opacity: 1;
  }
  
  50% {
    bottom: 0.2em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}